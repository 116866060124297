/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

@import 'material-icons/iconfont/material-icons.scss';

@import "style/theming/palette";

@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.eot');
    src: url($font-file + 'Ita.eot');
    src: url($font-file + 'Ita.woff') format('woff');
    src: url($font-file + '.woff') format('woff');
    font-weight: $font-weight;
    font-style: normal;
  }
}
@include font('DGUVMetaWeb-Normal', 'assets/fonts/dguv/DGUVMetaWeb-Normal', 'normal');
@include font('DGUVMetaWeb-Bold', 'assets/fonts/dguv/DGUVMetaWeb-Bold', 'bold');


$dguv-primary: mat.m2-define-palette($md-dguvpalette, 500);
//$app-primary: mat.define-palette(mat.$indigo-palette);
$dguv-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dguv-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Typography config based on the default, but using "Comic Sans" as the
// default font family for all levels.
$dguv-typography: mat.m2-define-typography-config(
  $font-family: 'DGUVMetaWeb-Normal',
);

$dguv-theme: mat.m2-define-light-theme((
  color: (
    primary: $dguv-primary,
    accent: $dguv-accent,
    warn: $dguv-warn,
  ),
  typography: $dguv-typography,
  density: -1, // default is 0
));

$dguv-blue: #00529C;

$prio1-color: #8f8f8f;
$prio2-color: green;
$prio3-color: gold;
$prio4-color: darkred;

@include mat.all-component-themes($dguv-theme);

body, html {
  margin: 0;
  font-family: DGUVMetaWeb-Normal, sans-serif;
  height: 100%;
  position: relative;
}

a.mdc-button,
button.mat-mdc-outlined-button,
button.mat-mdc-raised-button,
button.mat-mdc-unelevated-button
{
  border-radius: 0px;
}

/** SideNav Button Style */
.button-styles{
  $large-size-button: 64px;
  $large-size-icon: 36px;

  &.icon-button-large {
    width: $large-size-button;
    height: $large-size-button;
    line-height: $large-size-button;
    .mat-icon {
      font-size: $large-size-icon;
      width: $large-size-icon;
      height: $large-size-icon;
      line-height: $large-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $small-size-button: 24px;
  $small-size-icon: 18px;

  &.icon-button-small {
    width: $small-size-button;
    height: $small-size-button;
    line-height: $small-size-button;
    .mat-icon {
      font-size: $small-size-icon;
      width: $small-size-icon;
      height: $small-size-icon;
      line-height: $small-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}

a:active {
  color: #00e;
  opacity: 0.2;
}

a[mat-icon-button]{
 @extend .button-styles
}

button[mat-icon-button]{
 @extend .button-styles
}

.absolute {
  position: absolute;
}

.align-middle {
  vertical-align: middle;
  margin: auto;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.basis-2\/3 {
  flex-basis: 66.666667%;
}

.black-icon {
  color: black;
}

.black-icon:hover {
  cursor: pointer;
  color: $dguv-blue;
}

.red-icon {
  @extend .black-icon;
  color: $prio4-color !important;
}

.block {
  display: block;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-gray-300 {
  border-color: rgb(209 213 219);
}

.border-gray-700 {
  border-color: rgb(55 65 81);
}

.col-end-12 {
  grid-column-end: 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-3 {
  grid-column-start: 3;
}

.copy-icon {
  height: 3.25rem !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.file-input {
  display: none;
}

.flex {
  display: flex;
}

.font-semibold {
  font-weight: 600;
}

.gap-2 {
  gap: 0.5rem; /* 8px */
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid {
  display: grid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-items-center {
  justify-items: center;
}

.items-stretch {
  align-items: stretch;
}

.h-7 {
  height: 1.75rem; /* 28px */
}

.h-14 {
  height: 3.5rem;
}

.h-full {
  height: 100%;
}

.h-full-view {
  height: 100vh;
}

.mat-form-field {
  font-size: 12px; // example
}

.max-w-lg {
  max-width: 32rem; /* 512px */
}

.max-w-sm {
  max-width: 24rem; /* 384px */
}

.max-w-max {
  max-width: max-content;
}

.min-h-full {
  min-height: 100%;
}

.min-w-full {
  min-width: 100%;
}

.opacity-50 {
  opacity: 0.5;
}

.spacer {
  flex: 1 1 auto;
}

.align-bottom {
  vertical-align: bottom;
}

.bottom-0 {
  bottom: 0px;
}

.container {
  max-width: 1360px;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.mouse-pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 0.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem; /* 20px */
}

.mb-6 {
  margin-bottom: 1.5rem; /* 24px */
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-2\.5 {
  margin-left: 0.625rem; /* 10px */
}

.ml-3 {
  margin-left: 0.75rem; /* 12px */
}

.mr-2\.5 {
  margin-right: 0.625rem; /* 10px */
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt--2\.5 {
  margin-top: -0.625rem; /* -10px */
}

.mt--3 {
  margin-top: -0.75rem; /* -12px */
}

.mt-4 {
  margin-top: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-1\.5 {
  margin-left: 0.375rem; /* 6px */
  margin-right: 0.375rem; /* 6px */
}

.mx-2 {
  margin-left: 0.5rem; /* 8px */
  margin-right: 0.5rem; /* 8px */
}

.mx-2\.5 {
  margin-left: 0.625rem; /* 10px */
  margin-right: 0.625rem; /* 10px */
}

.my-2\.5  {
  margin-top: 0.625rem; /* 10px */
  margin-bottom: 0.625rem; /* 10px */
}

.my-6 {
  margin-top: 1.5rem; /* 24px */
  margin-bottom: 1.5rem; /* 24px */
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem; /* 4px */
}

.p-2 {
  padding: 0.5rem; /* 8px */
}

.pl-2 {
  padding-left: 0.5rem; /* 8px */
}

.pr-1 {
  padding-right: 0.25rem; /* 4px */
}

.pt-2 {
  padding-top: 0.5rem; /* 8px */
}

.pt-5 {
  padding-top: 1.25rem; /* 20px */
}

.prio-indicator {
  //border: 1px solid dimgray;
}

.prio-1 {
  @extend .prio-indicator;
  color: $prio1-color;
}

.prio-2 {
  @extend .prio-indicator;
  color: $prio2-color;
}

.prio-3 {
  @extend .prio-indicator;
  color: $prio3-color;
}

.prio-4 {
  @extend .prio-indicator;
  color: $prio4-color;
}

.right-0 {
  right: 0px;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-5 {
  grid-row: span 2 / span 2;
}

.self-stretch {
  align-self: stretch;
}

.sidenav {
  display: flex !important;
  align-items: start;
  justify-content: center;
  min-height: 100%;
  max-width: 250px;
  background: rgba(188, 190, 192, 0.37);
}

.sidenav-container {
  position: absolute !important;
  top: 70px;
  bottom: 60px;
  left: 0;
  right: 0;
  background-color: #efefef !important;
}

.text-base {
  font-size: 1rem !important; /* 16px */
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end !important;
}

.text-dguv-blue {
  color: $dguv-blue;
}

.text-gray-950 {
  color: rgb(3 7 18);
}

.text-lg {
  font-size: 1.125rem !important; /* 18px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
}

.text-xs {
  font-size: 0.75rem; /* 12px */
}

.text-4xl {
  font-size: 2.25rem !important; /* 36px */
  line-height: 2.5rem !important; /* 40px */
}

.top-0 {
  top: 0px;
}

.underline {
  text-decoration-line: underline;
}

.uppercase {
  text-transform: uppercase;
}

mat-icon.sidenav-icon {
  height: 48px !important;
  width: 48px !important;
  font-size: 48px !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.ng-toast-stack-container {
  top: 80vh !important;
  right: calc(50vw - (384px / 2)) !important;
  align-items: flex-end !important;
}

.static {
  position: static;
}
.fixed {
  position: fixed;
}

.relative {
  position: relative;
}
.sticky {
  position: sticky;
}

.sticky-toolbar {
  position: sticky; /* oder 'sticky' falls nötig und funktionierend */
  z-index: 1000; /* Höher als der Inhalt des Dialogs */
}

.w-auto {
  width: auto;
}

.w-10 {
  width: 2.5rem; /* 40px */
}

.w-1\/4 {
  width: 25%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/4 {
  width: 75%;
}

.w-16 {
  width: 4rem; /* 64px */
}

.w-20 {
  width: 5rem; /* 80px */
}

.w-24 {
  width: 6rem; /* 96px */
}

.w-32 {
  width: 8rem; /* 128px */
}

.w-72 {
  width: 18rem; /* 288px */
}

.w-full {
  width: 100%;
}

.z-50 {
  z-index: 50;
}

//Color to the DGUV Icon
.dguv-blue-filter{
  filter: brightness(0) saturate(100%) invert(19%) sepia(82%) saturate(2328%) hue-rotate(194deg);
}

 .black-chip-text button.mdc-evolution-chip__action.mat-mdc-chip-action.mdc-evolution-chip__action--primary span{
  color: black !important;
}

.red-chip {
  background-color: $prio4-color  !important;
}
.yellow-chip {
  @extend .black-chip-text;
  background-color: $prio3-color !important;
}
.green-chip {
  background-color: $prio2-color !important;
}
.white-chip {
  @extend .black-chip-text;
  @extend .prio-indicator;
  background-color: $prio1-color !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.mat-mdc-form-field-infix {
  min-height: 13px !important;
}

.highlighted {
  background: #e1edf6 !important;
}

.selected {
  background-color: red;
}

.mat-row.highlighted {
  background: #e1edf6 !important;
}

.mat-mdc-row:hover .mat-mdc-cell {
  border-color: #e1edf6;
  background: #e1edf6 !important;
}

.custom-drawer .mat-drawer-container {
  background-color: whitesmoke !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(0,0,0,0.2);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  top: 0;
  bottom: 0;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}


.custom-form-field .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff; /* Hintergrundfarbe auf Weiß setzen */
}

.dark-form-field  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke !important; /* Hintergrundfarbe auf Weiß setzen */
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff ;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
  color: black;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--selected) {
  background-color: #424242 !important;
}


.table-responsive {
  overflow-x: auto;
}
.table-responsive table {
  white-space: nowrap;
}

.scrollable-container{
  overflow-x: auto;
}

.detail-search-row {
  height: 60px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: white !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing {
  color: #afafaf !important;
}
